<template>
  <div id="app">
    <v-app>
      <loader-modal />
      <template v-if="isAuth && currentUser">
        <top-bar />
        <left-panel-nav-drawer v-if="!$vuetify.breakpoint.xs" />
        <template v-else>
          <mobile-nav-drawer v-model="drawer" />
          <v-app-bar
            app
            collapse-on-scroll
            elevation="1"
            v-if="$vuetify.breakpoint.xs"
          >
            <v-app-bar-nav-icon @click="drawer = true" />
            <v-toolbar-title>{{ documentTitle }}</v-toolbar-title>
          </v-app-bar>
        </template>
      </template>
      <v-main>
        <v-container fluid class="fill-height pa-0">
          <router-view />
        </v-container>
      </v-main>
      <app-footer app />
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppFooter from './components/AppFooter.vue'
import LoaderModal from './components/LoaderModal.vue'
import LeftPanelNavDrawer from './components/nawDrawer/LeftPanelNavDrawer.vue'
import MobileNavDrawer from './components/nawDrawer/MobileNavDrawer.vue'
import TopBar from './components/TopBar.vue'
import { FETCH_GLOBAL_SETTINGS, FETCH_LOCALES } from './store/actions.type'
import { ADD_NOTIFICATION } from './store/mutations.type'

export default {
  components: {
    AppFooter,
    LeftPanelNavDrawer,
    MobileNavDrawer,
    LoaderModal,
    TopBar
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    ...mapGetters(['isAuth', 'currentUser', 'documentTitle']),
    timezone: () => process.env.VUE_APP_DEFAULT_TIME_ZONE
  },

  watch: {
    isAuth: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.dispatch(FETCH_GLOBAL_SETTINGS)
          this.$store.dispatch(FETCH_LOCALES)
        }
      }
    }
  },

  sockets: {
    reconnect() {
      this.$store.dispatch(FETCH_GLOBAL_SETTINGS, { isReload: true })
      this.$store.dispatch(FETCH_LOCALES, { isReload: true })
      this.$store.commit(ADD_NOTIFICATION, {
        text: this.$t('connectionRestored'),
        color: '#169605'
      })
    },
    disconnect() {
      this.$store.commit(ADD_NOTIFICATION, {
        text: this.$t('connectionLost'),
        color: '#ac0000'
      })
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'vue-toastr-2/dist/vue-toastr-2.min.css';

.toast {
  font-family: 'Roboto', sans-serif;
}

.hidden {
  display: none;
}

a {
  text-decoration: none;
}

.border {
  border: solid 1px;
  &--error {
    border-color: red;
  }
}
.table {
  &-container {
    min-height: 100%;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;

    .v-data-footer__select {
      margin-left: 0 !important;
    }

    // .v-data-table,
    .v-data-table__wrapper {
      padding-bottom: 4rem;
    }
  }
}

.v-breadcrumbs {
  &__item {
    span::first-letter {
      text-transform: uppercase;
    }
  }
}

.v-list-item {
  &__title::first-letter {
    text-transform: uppercase;
  }
  &:focus::before {
    opacity: 0 !important;
  }
}

.v-alert {
  &__content::first-letter {
    text-transform: uppercase;
  }
}

.v-size--small {
  height: 28px;
  min-width: 28px !important;
}

.m_title {
  display: inline-block !important;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}
.word-wrap {
  word-break: break-word;
  white-space: break-spaces;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea,
.theme--light.v-input--is-disabled .v-select__slot .v-select__selections,
.theme--light.v-input--is-disabled .v-select__selections,
.theme--light .v-select__selection--disabled {
  color: rgba(0, 0, 0, 1) !important;
}

a .v-input--is-disabled,
a .v-input--is-disabled input,
a .v-input--is-disabled textarea,
a .v-input--is-disabled .v-select__slot .v-select__selections,
a .v-input--is-disabled .v-select__selections,
a .v-select__selection--disabled {
  color: var(--v-anchor-base) !important;
}

.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  -o-border-image: -o-repeating-linear-gradient(
      left,
      rgba(0, 0, 0, 0.87) 0,
      rgba(0, 0, 0, 0.87) 2px,
      transparent 0,
      transparent 4px
    )
    1 repeat;
  border-image: repeating-linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.87) 0,
      rgba(0, 0, 0, 0.87) 2px,
      transparent 0,
      transparent 4px
    )
    1 repeat;
}

.theme--light.v-label--is-disabled {
  color: rgba(0, 0, 0, 0.6);
}

.theme--dark.v-input--is-disabled,
.theme--dark.v-input--is-disabled input,
.theme--dark.v-input--is-disabled textarea,
.theme--dark.v-input--is-disabled .v-select__slot .v-select__selections,
.theme--dark.v-input--is-disabled .v-select__selections,
.theme--dark .v-select__selection--disabled {
  color: #fff !important;
}

.sticky {
  position: sticky;
  z-index: 1;
}

.theme--light.v-data-table .sticky {
  background-color: #fff;
}

.theme--dark.v-data-table .sticky {
  background-color: #1e1e1e;
}

.v-data-table tr:hover {
  .sticky {
    background-color: inherit !important;
  }
}

.left-0 {
  left: 0;
}

.left-50 {
  left: 50px;
}

.left-100 {
  left: 98px;
}

.br-0 {
  border-right: none !important;
}

.bl-0 {
  border-left: none !important;
}

.bx-0 {
  border-left: none !important;
  border-right: none !important;
}

.v-app-bar--is-scrolled {
  width: 63px;
}

.theme--dark.v-data-table .red:hover {
  background-color: #d32f2f !important;
  .sticky {
    background-color: #d32f2f !important;
  }
}

.theme--light.v-data-table .red:hover {
  background-color: #ef9a9a !important;
  .sticky {
    background-color: #ef9a9a !important;
  }
}

.transfer-panel .v-expansion-panel-content__wrap {
  padding: 0;
}

.v-autocomplete span.v-chip__content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding-right: 18px;
  height: auto;

  & button {
    position: absolute;
    right: 10px;
    top: calc(50% - 9px);
  }
}

.theme--light .extension-panel {
  border: solid 1px rgba(0, 0, 0, 0.38);
}

.theme--dark .extension-panel {
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.not-active-row {
  opacity: 0.5;
}

.not-active-cell {
  text-decoration: line-through;
}
</style>
