import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'

// import i18n from '@/i18n/i18n'
import { PURGE_AUTH } from '@/store/mutations.type'

import axiosRetry from './axios-retry'

let isInitialized = false

const AxiosService = {
  init(router, store) {
    if (isInitialized) {
      throw new Error('Axios Service should be initialized only once!')
    }

    isInitialized = true

    Vue.use(VueAxios, axios)

    // Vue.axios.interceptors.request.use((config) => {
    //   config.headers.common.locale = i18n.locale
    //   return config
    // })

    axiosRetry(Vue.axios, {
      retries: 2,
      retryDelay: axiosRetry.exponentialDelay
    })

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

    Vue.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            store.commit(PURGE_AUTH)
            if (router.currentRoute.name !== 'Login') {
              router.push({ name: 'Login' })
            }
          }

          if (error.response.status === 404) {
            if (router.currentRoute.name !== 'Error') {
              router.push({ name: 'Error' })
            }
          }
        }
        return Promise.reject(error)
      }
    )
  },

  setAuthorizationHeader(token) {
    if (token) {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  },

  setLanguageHeader(lang) {
    Vue.axios.defaults.headers.common['Accept-Language'] = lang
  }
}

export default AxiosService
