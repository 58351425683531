/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import findIndex from 'lodash/findIndex'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import Vue from 'vue'

import { FETCH_ORDER, FETCH_ORDERS } from '../../actions.type'
import {
  ADD_NOTIFICATION,
  CREATE_ORDER,
  SET_ORDER_ALL_COUNT,
  SET_ORDER_ALL_REQUEST,
  SET_ORDER_ALL_VALUE,
  SET_ORDER_ONE_REQUEST,
  SET_ORDER_ONE_VALUE,
  SET_ORDERS_TABLE_FILTERS,
  SET_ORDERS_TABLE_PARAMS,
  UPDATE_ORDER
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined,
      params: {
        itemsPerPage: 50,
        page: 1,
        sortBy: [],
        sortDesc: [],
        where: {}
      },
      ordersTableParams: {},
      ordersTableFilters: {}
    },
    value: [],
    count: 0
  },
  one: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: undefined
  }
}

const getters = {
  orderOneValue: (state) => state.one.value,
  orderOneRequest: (state) => state.one.request,
  orderAllValue: (state) => state.all.value,
  orderAllCount: (state) => state.all.count,
  orderAllRequest: (state) => state.all.request,
  orderAllById: (state) => keyBy(state.all.value, 'id'),
  ordersTableParams: (state) => state.all.request.ordersTableParams,
  ordersTableFilters: (state) => state.all.request.ordersTableFilters,
  orderAllByClientId: (state) => {
    const result = {}
    map(state.all.value, (val) => {
      if (val.location.client) {
        if (result[val.location.client.id]) {
          result[val.location.client.id].push(val)
        } else {
          result[val.location.client.id] = [val]
        }
      }
    })
    return result
  },
  orderAllByLocationId: (state) => {
    const result = {}
    map(state.all.value, (val) => {
      if (val.location) {
        if (result[val.location.id]) {
          result[val.location.id].push(val)
        } else {
          result[val.location.id] = [val]
        }
      }
    })
    return result
  },
  orderAllByEquipmentId: (state) => {
    const result = {}
    map(state.all.value, (val) => {
      if (val.equipment) {
        if (result[val.equipment.id]) {
          result[val.equipment.id].push(val)
        } else {
          result[val.equipment.id] = [val]
        }
      }
    })
    return result
  },
  orderAllByContractId: (state) => {
    const result = {}
    map(state.all.value, (val) => {
      if (val.contract) {
        if (result[val.contract.id]) {
          result[val.contract.id].push(val)
        } else {
          result[val.contract.id] = [val]
        }
      }
    })
    return result
  },
  orderAllByStaffId: (state) => {
    const result = {}
    map(state.all.value, (val) => {
      if (val.staff) {
        if (result[val.staff.id]) {
          result[val.staff.id].push(val)
        } else {
          result[val.staff.id] = [val]
        }
      }
    })
    return result
  }
}

const actions = {
  async [FETCH_ORDERS]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }

    if (
      this.getters.currentUser.role.isOrderByZone &&
      !this.getters.currentUser.zones.length
    ) {
      commit(SET_ORDER_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_ORDER_ALL_VALUE, [])
      commit(SET_ORDER_ALL_COUNT, 0)
      return
    }

    try {
      const {
        data: { items, count }
      } = await Vue.axios.get('order', {
        headers: {
          ...(params.itemsPerPage && { 'Items-Per-Page': params.itemsPerPage }),
          ...(params.page && { Page: params.page }),
          ...(params.sortBy && { 'Sort-By': params.sortBy }),
          ...(params.sortDesc && { 'Sort-Desc': params.sortDesc }),
          ...(params.where && { Where: params.where })
        },
        'axios-retry': {
          beforeTry: () => {
            commit(SET_ORDER_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_ORDER_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_ORDER_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_ORDER_ALL_VALUE, items)
      commit(SET_ORDER_ALL_COUNT, count)
    } catch (e) {
      commit(SET_ORDER_ALL_REQUEST, {
        isFatalError: true
      })
    }
  },
  async [FETCH_ORDER]({ commit, state }, id) {
    if (state.one.request.isLoading || state.one.request.isError) {
      return
    }
    try {
      const { data } = await Vue.axios.get(`order/${id}`, {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_ORDER_ONE_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_ORDER_ONE_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_ORDER_ONE_REQUEST, {
        isSuccess: true
      })
      commit(SET_ORDER_ONE_VALUE, data)
    } catch (e) {
      commit(SET_ORDER_ONE_REQUEST, {
        isFatalError: true
      })
    }
  },

  async SOCKET_CREATE_ORDER({ commit }, order) {
    const { role, zones: userZones, id: userId } = this.getters.currentUser
    const { staff, location, equipment } = order

    const isOrderByZoneValid =
      role.isOrderByZone &&
      [equipment.model.zones, location.client.zones, location.city.zones].every(
        (zones) =>
          zones.some((zone) =>
            userZones.some((userZone) => userZone.id === zone.id)
          )
      )

    const isOrderOnlyTheirValid =
      role.isOrderOnlyTheir && (!staff || staff.id === userId)

    if (
      (!role.isOrderOnlyTheir && !role.isOrderByZone) ||
      (isOrderOnlyTheirValid && !role.isOrderByZone) ||
      (isOrderOnlyTheirValid && isOrderByZoneValid) ||
      (isOrderByZoneValid && !role.isOrderOnlyTheir)
    ) {
      commit(ADD_NOTIFICATION, {
        text: `${order.orderStatus[
          this.getters.isGlobalLocale ? 'titleI18n' : 'title'
        ].toUpperCase()}: ${String(order.id).padStart(7, '0')}, ${
          order.location.client[
            this.getters.isGlobalLocale ? 'titleI18n' : 'title'
          ]
        }, ${
          order.location[this.getters.isGlobalLocale ? 'titleI18n' : 'title']
        } - ${
          order.orderType[this.getters.isGlobalLocale ? 'titleI18n' : 'title']
        }`,
        color: order.orderStatus.color
      })
    }

    commit(CREATE_ORDER, order)
  },

  async SOCKET_UPDATE_ORDER({ commit }, order) {
    const { role, zones: userZones, id: userId } = this.getters.currentUser
    const { staff, location, equipment } = order

    const isOrderByZoneValid =
      role.isOrderByZone &&
      [equipment.model.zones, location.client.zones, location.city.zones].every(
        (zones) =>
          zones.some((zone) =>
            userZones.some((userZone) => userZone.id === zone.id)
          )
      )

    const isOrderOnlyTheirValid =
      role.isOrderOnlyTheir && (!staff || staff.id === userId)

    if (
      (!role.isOrderOnlyTheir && !role.isOrderByZone) ||
      (isOrderOnlyTheirValid && !role.isOrderByZone) ||
      (isOrderOnlyTheirValid && isOrderByZoneValid) ||
      (isOrderByZoneValid && !role.isOrderOnlyTheir)
    ) {
      commit(ADD_NOTIFICATION, {
        text: `${order.orderStatus[
          this.getters.isGlobalLocale ? 'titleI18n' : 'title'
        ].toUpperCase()}: ${String(order.id).padStart(7, '0')}, ${
          order.location.client[this.isGlobalLocale ? 'titleI18n' : 'title']
        }, ${
          order.location[this.getters.isGlobalLocale ? 'titleI18n' : 'title']
        }, ${
          order.staff[this.getters.isGlobalLocale ? 'lastNameI18n' : 'lastName']
        }`,
        color: order.orderStatus.color
      })

      commit(UPDATE_ORDER, order)
    }
  }
}

const mutations = {
  [SET_ORDER_ONE_REQUEST](state, request) {
    state.one.request = {
      ...request
    }
  },
  [SET_ORDER_ONE_VALUE](state, value) {
    state.one.value = value
  },
  [SET_ORDER_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_ORDER_ALL_VALUE](state, value) {
    state.all.value = value
  },
  [SET_ORDER_ALL_COUNT](state, count) {
    state.all.count = count
  },
  [SET_ORDERS_TABLE_FILTERS](state, { filters, path }) {
    state.all.request.ordersTableFilters[path] = filters
  },
  [SET_ORDERS_TABLE_PARAMS](state, { params, path }) {
    state.all.request.ordersTableParams[path] = params
  },
  [UPDATE_ORDER](state, updatedOrder) {
    state.all.value.splice(
      findIndex(state.all.value, { id: updatedOrder.id }),
      1,
      updatedOrder
    )

    if (state.one.value?.id === updatedOrder.id) {
      state.one.value = { ...updatedOrder }
    }
  },
  [CREATE_ORDER](state, createdOrder) {
    state.all.value.push(createdOrder)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
